import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IRepo, IRepoResponse } from './interfaces';
import { environment } from 'src/environments/environment';
import {convertToGMTTimeStamp} from "src/app/shared/app-utility"

@Injectable({
  providedIn: 'root'
})
export class RepoService {
  appRoot = environment.appRoot;
  repoIssueDetailRoute = this.appRoot+'/api/gitrequests/type/issue/state/all/';
  repoPullDetailRoute = this.appRoot+'/api/gitrequests/type/pull/state/all/';

  repoIssueDetailRouteInWeeks = this.appRoot+'/api/gitrequests/bydays/filter';
  repoPullDetailRouteInWeeks = this.appRoot+'/api/gitrequests/bydays/filter';
  repoCommitDetailRouteInWeeks = this.appRoot+'/api/commit/bydays/filter';

  repoPullDetailCountRoute = this.appRoot+'/api/gitrequests/type/pull/state/all/count';
  repoCommitDetailCountRoute = this.appRoot+'/api/commit/count';
  repoCommitDetailRoute = this.appRoot+'/api/commit/';
  repoCommitFilterRouter = this.appRoot+'/api/commit/';
  addRepoRoute = this.appRoot+'/api/collector/item';
  private readonly ADD_REPO_API = this.appRoot + '/api/collector/items'; 

  constructor(private http: HttpClient) { }

  private getParams(componentId: string, numberOfDays: number) {
    return {
      params: new HttpParams().set('componentId', componentId).set('numberOfDays', numberOfDays.toFixed(0))
    };
  }

  private getParamsBranch(componentId: string, numberOfDays: number,branch:string[], collectorItemId:string) {
    return {
      params: new HttpParams().set('componentId', componentId).set('numberOfDays', numberOfDays.toFixed(0)).set('branch',branch.join(',')).set('collectorItemId', collectorItemId)
    };
  }

  private getParamsforWeeks(componentId: string, numberOfDays: number, type: string, state: string) {
    return {
      params: new HttpParams().set('componentId', componentId).set('numberOfDays', numberOfDays.toFixed(0)).set('type', type).set('state', state)
    };
  }

  private getParamsforWeeksBranch(componentId: string, numberOfDays: number,branch:string[], type: string, state: string, collectorItemId:string) {
    return {
      params: new HttpParams().set('componentId', componentId).set('numberOfDays', numberOfDays.toFixed(0)).set('branch',branch.join(',')).set('type', type).set('state', state).set('collectorItemId', collectorItemId)
    };
  }

  private getDateParams(componentId: string, startDate: string, endDate: string) {
    return {
      params: new HttpParams().set('componentId', componentId).set('commitDateBegins', convertToGMTTimeStamp(startDate).setUTCHours(0,0,0,0)).set('commitDateEnds', convertToGMTTimeStamp(endDate).setUTCHours(23, 59, 59, 999))
    };
  }

  private getDateParamsBranch(componentId: string, startDate: string, endDate: string,branch:string[],collectorItemId: string) {
    return {
      params: new HttpParams().set('componentId', componentId).set('commitDateBegins', convertToGMTTimeStamp(startDate).setUTCHours(0,0,0,0)).set('commitDateEnds',convertToGMTTimeStamp(endDate).setUTCHours(23, 59, 59, 999)).set('branch',branch.join(',')).set('collectorItemId', collectorItemId)
    };
  }

  addNewRepo(newRepoData: {}) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(this.addRepoRoute, newRepoData, httpOptions);
  }

  saveRepos(repoItems): Observable<any> {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(this.ADD_REPO_API, repoItems, httpOptions);
  }

  fetchIssues(componentId: string, numberOfDays: number,branch:string[], collectorItemId:string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoIssueDetailRoute, this.getParamsBranch(componentId, numberOfDays,branch, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchIssuesinWeeks(componentId: string, numberOfDays: number,branch:string[], type: string, state: string, collectorItemId:string):Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoIssueDetailRouteInWeeks, this.getParamsforWeeksBranch(componentId, numberOfDays,branch, type, state, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchCommits(componentId: string, numberOfDays: number, branch : string[], collectorItemId: string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoCommitDetailRoute, this.getParamsBranch(componentId, numberOfDays,branch, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchCommitsinWeeks(componentId: string, numberOfDays: number,branch:string[], type: string, state: string, collectorItemId:string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoCommitDetailRouteInWeeks, this.getParamsforWeeksBranch(componentId, numberOfDays,branch, type, state, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchPullRequests(componentId: string, numberOfDays: number,branch:string[], collectorItemId:string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoPullDetailRoute, this.getParamsBranch(componentId, numberOfDays,branch, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchPullRequestsinWeeks(componentId: string, numberOfDays: number, branch:string[], type: string, state: string, collectorItemId:string):Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(this.repoPullDetailRouteInWeeks, this.getParamsforWeeksBranch(componentId, numberOfDays,branch, type, state, collectorItemId)).pipe(
      map(response => response.result));
  }

  fetchCodeDataWithFilter(componentId: string, startDate: string, endDate:  string, isPullRequest : boolean,branch:string[], collectorItemId: string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(!isPullRequest ? this.repoPullDetailRoute:this.repoCommitFilterRouter,  this.getDateParamsBranch(componentId, startDate, endDate,branch,collectorItemId)).pipe(
      map(response => {
        return response.result;
      }));
  }

  fetchCodeDataCount(componentId: string, startDate: string, endDate:  string, isPullRequest : boolean,branch:string[],collectorItemId: string): Observable<IRepo[]> {
    return this.http.get<IRepoResponse>(isPullRequest ? this.repoCommitDetailCountRoute:this.repoPullDetailCountRoute,  this.getDateParamsBranch(componentId, startDate, endDate,branch,collectorItemId)).pipe(
      map(response => {
        return response.result;
      }));
  }
}

