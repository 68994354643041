<div class="repo-header">
    <div id="title" class="repo-heading">Repo Detail <div class="pull-right" id="lastXdays" (change)='onDaysChange($event)' [(ngModel)]="selectDays">
            <select class='select-option'>
                <option class='option' value="last 15 days" selected>{{lastFifteenDays}}</option>
                <option class="option" value="last 30 days">{{lastThirtyDays}}</option>
                <option class='option' value="last 60 days">{{lastSixtyDays}}</option>
                <option class='option' value="last 90 days">{{lastNinetyDays}}</option>
            </select>
        </div>
        <div class="line"></div>
    </div>

    <div class="repo-detail">
        <label for="repoDropdown">Repo</label>
        <select id="repoDropdown" class="repo-dropdown" (change)="onRepoSelected($event)">
            <option *ngFor="let collector of allCollectorItems" [value]="collector?.collectorItemId">{{collector?.repoName}}</option>
        </select>
    </div>

      <div class="repo-detail">
        <span class="repo-detail-label">Branches</span>
        <div class="form-field">
          <div class="multi-select" (click)="toggleDropdown()">
            <div class="multi-select-trigger">
              {{ selectedBranches.length > 0 ? selectedBranches[0] : '' }}
              <span *ngIf="selectedBranches.length > 1" class="additional-selection">
                (+{{ selectedBranches.length - 1 }} {{ selectedBranches.length === 2 ? 'other' : 'others' }})
              </span>
            </div>
            <div class="dropdown" *ngIf="isDropdownOpen">
              <div 
                *ngFor="let branch of repoBranch" 
                class="option"
                [class.disabled]="isLoading || (repoBranch.length - selectedBranches.length) === repoBranch.length - 1 && selectedBranches.includes(branch)"
                (click)="toggleSelection(branch)"
              >
                <span class="tick" *ngIf="selectedBranches.includes(branch)">&#10003;</span>
                <span class="val"> {{ branch }} </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isLoading" class="loading-message">
            Chart is loading...
        </div>
      </div>

    <div class="line"></div>
</div>
<ng-template appLayout></ng-template>