
<p-treeTable #tt [scrollable]="true" scrollHeight="500px" [columns]="cols" [value]="files" [loading]="loading" (onNodeExpand)="callFetchNode($event)">
   
    <ng-template pTemplate="header" let-columns>
        <tr class="table-header-row">
          <th class="table-header-col" *ngFor="let col of cols">
            {{ col.header }}
          </th>
        </tr>
        <tr class="{{disableFilter ? 'hide-filter' : ''}}">
            <th *ngFor="let col of cols">
              <input
                pInputText
                type="text"
                class="w-90"
                (input)="
                  tt.filter($event.target.value, col.field, col.filterMatchMode)
                "
              />
            </th>
          </tr>
      </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <ng-container *ngIf="rowData.url; else checkBugs">
            <tr [ttRow]="rowNode">
                <td [ngClass]="{ 'font-bold': col === 'Number' }"  class="text-ellipsis" container="body" tooltipClass="message-tooltip" ngbTooltip=" {{ rowData.taskNumber}}  {{rowData.taskName}}">
                    <p-treeTableToggler  [rowNode]="rowNode" (change)="callFetchNode(rowData)"> </p-treeTableToggler>
                    <a href={{rowData.url}} target="_blank">{{ rowData.taskNumber}}  {{rowData.taskName}}</a>
                </td>
                <td>{{ rowData.assignee }}</td>
                <td>{{ rowData.percentCompletion }}</td>
                <td>{{ rowData.taskStatus }}</td>
                <td>{{ rowData.typeName }}</td>
                <td>{{ rowData.startDate | date }}</td>
                <td>{{ rowData.targetDate | date }}</td>
                <td>{{ rowData.elapseTime ? rowData.elapseTime + ' days' : ""}}</td>
            </tr>
          </ng-container>
            <ng-template #checkBugs>
              <ng-container *ngIf="rowData.bugs; else elseBlock">
                <tr  [ttRow]="rowNode" class="table-data-row">
                  <td class="table-data-col" *ngFor="let col of cols; let i = index">
                      <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
                      <ng-container *ngIf="i === 0 && showToolTip; else elseTemplate">
                        <div class="data-item" container="body" tooltipClass="message-tooltip" ngbTooltip="{{showCategoryWithToolTip ? rowData.categoryType : rowData[col.field]}}">{{ rowData[col.field] }}</div>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <a *ngIf="i !== 0" (click)="onColumnClick(col, rowData)">
                          {{ rowData[col.field] }}
                        </a>
                        <span *ngIf="i===0" class="random">{{ rowData[col.field] }}</span>
                     </ng-template>
                  </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
                <tr  [ttRow]="rowNode" class="table-data-row">
                    <td class="table-data-col" *ngFor="let col of cols; let i = index">
                        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
                        <ng-container *ngIf="i === 0 && showToolTip; else branchTooltipTemplate">
                          <div class="data-item" container="body" tooltipClass="message-tooltip" ngbTooltip="{{showCategoryWithToolTip ? rowData.categoryType : rowData[col.field]}}">{{ rowData[col.field] }}</div>
                        </ng-container>

                        <ng-template #branchTooltipTemplate>
                          <ng-container *ngIf="i === 0 && showBranchTooltip; else elseTemplate">
                            <span  [ngbTooltip]="rowData[col.field]?.length > 32 ? rowData[col.field]  : null"> 
                              {{ getTruncatedTooltipText(rowData[col.field]) }}
                            </span> 
                          </ng-container>
                        </ng-template>

                        <ng-template #elseTemplate>
                           {{ rowData[col.field] }}
                       </ng-template>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="cols.length" *ngIf="loading === false">No record found.</td>
              <td [attr.colspan]="cols.length" *ngIf="loading === true">Fetching records.</td>
            </tr>
          </ng-template>
</p-treeTable>