import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, startWith, switchMap, take, map } from 'rxjs/operators';
import { DashboardService } from 'src/app/shared/dashboard.service';
import { LayoutDirective } from 'src/app/shared/layouts/layout.directive';
import { WidgetComponent } from 'src/app/shared/widget/widget.component';
import { RepoService } from '../repo.service';
import { REPO_CHARTS } from './repo-charts';
import { IRepo, IRepoResult } from '../interfaces';
import { CollectorService } from '../../../shared/collector.service';
import { FormControl } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { SharedDataService } from '../shared-data.service';

// @ts-ignore
import moment from 'moment';
// tslint:disable-next-line:max-line-length
import { OneByTwoLayoutTableChartComponent } from '../../../shared/layouts/one-by-two-layout-table-chart/one-by-two-layout-table-chart.component';
import { WidgetState } from '../../../shared/widget-header/widget-state';
import { Router, ActivatedRoute } from '@angular/router';
import { FIFTEEN, LAST_FIFTEEN_DAYS, LAST_NINETY_DAYS, LAST_SIXTY_DAYS, LAST_THIRTY_DAYS, NINETY, SIXTY, THIRTY } from 'src/app/shared/app-constants';
import { groupBy } from 'src/app/shared/app-utility';


@Component({
  selector: 'app-repo-widget',
  templateUrl: './repo-widget.component.html',
  styleUrls: ['./repo-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class RepoWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly TOTAL_REPO_COUNTS_TIME_RANGES: number[] = [0, 7, 15, 30, 60, 90];
  // Reference to the subscription used to refresh the widget
  private intervalRefreshSubscription: Subscription;
  private params;

  selectDays: any ;
  repoName: string;
  branches = new FormControl([]);
  selectedBranches: string[] = [];
  repoBranch: string[];
  isLoading = false;
  lastFifteenDays: string = LAST_FIFTEEN_DAYS
  lastSixtyDays: string = LAST_SIXTY_DAYS
  lastThirtyDays: string = LAST_THIRTY_DAYS
  lastNinetyDays: string = LAST_NINETY_DAYS
  isDropdownOpen = false;
  allCollectorItems:any[]=[];
  private selectedCollectorItem:any;


  @ViewChild(LayoutDirective, { static: false }) childLayoutTag: LayoutDirective;



  constructor(componentFactoryResolver: ComponentFactoryResolver,
    cdr: ChangeDetectorRef,
    dashboardService: DashboardService,
    collectorService: CollectorService,
    private repoService: RepoService,
    public router: Router,


    public route: ActivatedRoute,
    private sharedDataService: SharedDataService) {
    super(componentFactoryResolver, cdr, dashboardService);
  }

  // Initialize the widget and set layout and charts.
  ngOnInit() {
    this.widgetId = 'repo0';
    this.layout = OneByTwoLayoutTableChartComponent;
    this.charts = REPO_CHARTS;
    this.auditType = 'CODE_REVIEW';
    this.init();
    this.loadRepoWidget();
    this.branches.setValue(this.repoBranch);
    this.sharedDataService.updateMultipleSelection(this.repoBranch);
    this.sharedDataService.updateDate(FIFTEEN);
    this.branches.valueChanges.subscribe((selectedBranches: string[]) => {
      this.selectedBranches = selectedBranches;
      this.sharedDataService.updateMultipleSelection(this.selectedBranches);
      if(this.params.numberOfDays != FIFTEEN){
        this.selectDays = this.params.numberOfDays;
      }
      else{
        this.selectDays = undefined;
      }
    });
  }

  onDaysChange(event) {
    this.selectDays = parseInt(event.target.value.replace(/^\D+|\D+$/g, ""));
    this.sharedDataService.updateDate(this.selectDays);
    this.startRefreshInterval();
  }

  // After the view is ready start the refresh interval.
  ngAfterViewInit() {
    this.startRefreshInterval();
  }

  onSelectBranch(){
    this.isLoading = true;
    const selectedBranches = this.branches.value as string[];
   if (selectedBranches.length === this.selectedBranches.length-1) {
    this.branches.setValue(selectedBranches);
  }
    this.startRefreshInterval();
    
}

  ngOnDestroy() {
    this.stopRefreshInterval();
  }


  loadRepoWidget() {
    this.dashboardService.dashboardConfig$.pipe(take(1),
      map(dashboard => {

        const repoCollector = dashboard.application.components[0].collectorItems.SCM;
        return repoCollector
      }))
      .subscribe(collectorData => {
        collectorData?.forEach(data => {
          if(data?.options){
            const repoName = data.options.url?.split("/").pop();
            const repoBranches = data.options.branches;
            const collectorItemId = data.id;
            if(repoName && repoBranches){
              this.allCollectorItems.push({
                repoName,
                repoBranches,
                collectorItemId
              })
            }
          }
        });
        if(this.allCollectorItems.length){
          this.updateSelectedRepoDetails(this.allCollectorItems[0]);
        }
      });
  }

  updateSelectedRepoDetails(collectorItem){
    this.selectedCollectorItem = collectorItem;
    this.repoBranch = collectorItem?.repoBranches;
    this.selectedBranches = this.repoBranch;
    this.repoName = collectorItem?.repoName;
    this.sharedDataService.updateMultipleSelection(this.selectedBranches);
  }
  
  onRepoSelected(event){
    const selectedCollectorId = event?.target?.value;
    if(selectedCollectorId) {
      this.isLoading = true;
      const collectorItem = this.allCollectorItems.find(item => item.collectorItemId === selectedCollectorId);
      this.updateSelectedRepoDetails(collectorItem);
      this.setDefaultIfNoData();
      this.startRefreshInterval();
    }
  }
 
  startRefreshInterval() {
    this.intervalRefreshSubscription = this.dashboardService.dashboardRefresh$.pipe(
      startWith(-1), // Refresh this widget separate from dashboard (ex. config is updated)
      distinctUntilChanged(), // If dashboard is loaded the first time, ignore widget double refresh
      // tslint:disable-next-line:no-shadowed-variable
      switchMap(_ => this.getCurrentWidgetConfig()),
      switchMap(widgetConfig => {
        if (!widgetConfig) {
          return of([]);
        }
        this.widgetConfigExists = true;
        this.state = WidgetState.READY;

        if (this.selectDays === undefined) {
          this.params = {
            componentId: widgetConfig.componentId,
            numberOfDays: FIFTEEN
          };
        } else {
          this.params = {
            componentId: widgetConfig.componentId,
            numberOfDays: this.selectDays
          };
        }
        return forkJoin([
          this.repoService.fetchCommits(this.params.componentId, this.params.numberOfDays,this.selectedBranches, this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          this.repoService.fetchPullRequests(this.params.componentId, this.params.numberOfDays,this.selectedBranches, this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          this.repoService.fetchIssues(this.params.componentId, this.params.numberOfDays,this.selectedBranches, this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          this.repoService.fetchCommitsinWeeks(this.params.componentId, this.params.numberOfDays, this.selectedBranches,'commit', 'all', this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          this.repoService.fetchPullRequestsinWeeks(this.params.componentId, this.params.numberOfDays,this.selectedBranches, 'pull', 'all', this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          this.repoService.fetchIssuesinWeeks(this.params.componentId, this.params.numberOfDays,this.selectedBranches, 'issue', 'all', this.selectedCollectorItem.collectorItemId).pipe(catchError(err => of(err))),
          [this.params.componentId]
       
        ])
      })
    ).subscribe(([commits, pulls, issues, commitsinWeeks, pullsinWeeks, issuesinWeeks, componentId]) => {
      this.hasData = false;
      if ((commits || pulls || issues) && (commits.length > 0 || pulls.length > 0 || issues.length > 0) &&
        (this.selectDays === undefined || this.selectDays == FIFTEEN)) {
        this.hasData = true;
        this.loadCharts(commits, pulls, issues, componentId);
      }
      if ((commitsinWeeks || pullsinWeeks || issuesinWeeks) && (commitsinWeeks.length > 0 || pullsinWeeks.length > 0 || issuesinWeeks.length > 0)
        && (this.params.numberOfDays == THIRTY || this.params.numberOfDays == SIXTY || this.params.numberOfDays == NINETY) && (this.selectDays != undefined && this.selectDays >FIFTEEN)) {
        this.hasData = true;
        this.loadChartsForWeeks(commits, pulls, issues, commitsinWeeks, pullsinWeeks, issuesinWeeks, componentId,this.params.numberOfDays)
      } else if(this.params.numberOfDays == THIRTY || this.params.numberOfDays == SIXTY || this.params.numberOfDays == NINETY){
        this.isLoading = false;
        this.setDefaultIfNoDataWithDays();
      }
      else if(!this.hasData){
        this.isLoading = false;
        this.setDefaultIfNoData();
      }
    });
  }

  loadCharts(commits: IRepo[], pulls: IRepo[], issues: IRepo[], componentId) {
    this.generateRepoPerDay(commits, pulls, issues, componentId);
    this.isLoading = false;
    this.generateTotalRepoCounts(commits, pulls, issues);
    super.loadComponent(this.childLayoutTag); 
  }

  loadChartsForWeeks(commits: IRepo[], pulls: IRepo[], issues: IRepo[], commitsinWeeks: IRepoResult[], pullsinWeeks: IRepoResult[], issuesinWeeks: IRepoResult[], componentId,noOfDays) {
    this.generateRepoPerDayForWeeks(commitsinWeeks, pullsinWeeks, issuesinWeeks, componentId,noOfDays);
    this.isLoading = false;
    this.generateTotalRepoCounts(commits, pulls, issues);
    super.loadComponent(this.childLayoutTag);
  }

  // Unsubscribe from the widget refresh observable, which stops widget updating.
  stopRefreshInterval() {
    if (this.intervalRefreshSubscription) {
      this.intervalRefreshSubscription.unsubscribe();
    }
  }

  public resetPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([this.router.routerState.snapshot.url], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    })
  }

  // *********************** REPO STATS PER DAY ************************
  generateRepoPerDay(commitResult: IRepo[], pullResult: IRepo[], issueResult: IRepo[], componentId) {
    if (!commitResult || !pullResult || !issueResult) {
      return;
    }
    const startDate = this.toMidnight(new Date());
    if (this.selectDays === undefined) {
      this.selectDays = FIFTEEN;
    }

    const [commitDataSeries, maxCommitCount] = this.collectDataArray(this.collectRepoCommits(commitResult), componentId);
    this.charts[0].data.dataPoints[0].series = commitDataSeries;
    const [pullDataSeries, maxPullCount] = this.collectDataArray(this.collectRepoPulls(pullResult), componentId);
    this.charts[0].data.dataPoints[1].series = pullDataSeries;
    const [issueDataSeries, maxIssueCount] = this.collectDataArray(this.collectRepoIssues(issueResult), componentId);
    this.charts[0].data.dataPoints[2].series = issueDataSeries;
    this.charts[0].data.componentId = componentId;
    const maxChartCount = Math.max(maxCommitCount, maxPullCount, maxIssueCount);
    if(maxChartCount<5 ){
      this.charts[0].data.yAxisTicks = Array.from(Array(maxChartCount+1).keys());
    } 
  }

  generateRepoPerDayForWeeks(commitResult: IRepoResult[], pullResult: IRepoResult[], issueResult: IRepoResult[], componentId,noOfDays) {
    if (!commitResult || !pullResult || !issueResult) {
      return;
    }
    commitResult = commitResult.values ? commitResult : [];
    pullResult = pullResult.values ? pullResult : [];
    

    const [pullDataSeries, maxPullCount] = this.collectDataArrayForWeek(this.collectRepoPullsForWeek(pullResult), componentId, pullResult,noOfDays);
    this.charts[0].data.dataPoints[1].series = pullDataSeries;
    const [commitDataSeries, maxCommitCount] = this.collectDataArrayForWeek(this.collectRepoCommitsForWeek(commitResult), componentId, commitResult,noOfDays);
    this.charts[0].data.dataPoints[0].series = commitDataSeries
    this.charts[0].data.componentId = componentId;
    const maxChartCount = maxPullCount > maxCommitCount ? maxPullCount : maxCommitCount;
    if(maxChartCount<5 ){
      this.charts[0].data.yAxisTicks = Array.from(Array(maxChartCount+1).keys());
    } 
  }

  collectContributorCount(allResults: IRepo[], type: string) {

    let lastDayCount = 0;
    let lastSevenDayCount = 0;
    let lastFourteenDayCount = 0;
    let lastThirtyDayCount = 0;
    let lastSixtyDayCount = 0;
    let lastNinetyDayCount = 0;

    const lastDayContributors = [];
    const lastSevenDayContributors = [];
    const lastFourteenDayContributors = [];
    const lastThirtyDayContributors = [];
    const lastSixtyDayContributors = [];
    const lastNinetyDayContributors = [];

    const today = this.toMidnight(new Date());
    const sevenDays = this.toMidnight(new Date());
    const fourteenDays = this.toMidnight(new Date());
    const thirtyDays = this.toMidnight(new Date());
    const sixtyDays = this.toMidnight(new Date());
    const ninetyDays = this.toMidnight(new Date());

    sevenDays.setDate(sevenDays.getDate() - 7);
    fourteenDays.setDate(fourteenDays.getDate() - 14);
    thirtyDays.setDate(thirtyDays.getDate() - 30);
    sixtyDays.setDate(sixtyDays.getDate() - 60);
    ninetyDays.setDate(ninetyDays.getDate() - 90);

    let timestamp;
    let user;
    let urlscm;
    // Setting values for commuters, contributors, and ideators
    allResults.forEach(currResult => {
      if (type === 'commit') {
        timestamp = currResult.scmCommitTimestamp;
        user = currResult.scmAuthorLogin?.toLowerCase();
        urlscm = currResult.scmUrl;
      } else {
        timestamp = currResult.timestamp;
        user = currResult.userId;
        urlscm = currResult.scmUrl;
      }
      if (this.checkRepoAfterDate(timestamp, today)) {
        lastDayCount++;
        if (lastDayContributors.indexOf(user) === -1) {
          lastDayContributors.push(user);
        }
      }
      if (this.checkRepoAfterDate(timestamp, sevenDays)) {
        lastSevenDayCount++;
        if (lastSevenDayContributors.indexOf(user) === -1) {
          lastSevenDayContributors.push(user);
        }
      }
      if (this.checkRepoAfterDate(timestamp, fourteenDays)) {
        lastFourteenDayCount++;
        if (lastFourteenDayContributors.indexOf(user) === -1) {
          lastFourteenDayContributors.push(user);
        }
      }
      if (this.checkRepoAfterDate(timestamp, thirtyDays)) {
        lastThirtyDayCount++;
        if (lastThirtyDayContributors.indexOf(user) === -1) {
          lastThirtyDayContributors.push(user);
        }
      }
      if (this.checkRepoAfterDate(timestamp, sixtyDays)) {
        lastSixtyDayCount++;
        if (lastSixtyDayContributors.indexOf(user) === -1) {
          lastSixtyDayContributors.push(user);
        }
      }
      if (this.checkRepoAfterDate(timestamp, ninetyDays)) {
        lastNinetyDayCount++;
        if (lastNinetyDayContributors.indexOf(user) === -1) {
          lastNinetyDayContributors.push(user);
        }
      }
    });
    return [lastDayContributors.length, lastSevenDayContributors.length, lastFourteenDayContributors.length,
    lastThirtyDayContributors.length, lastSixtyDayContributors.length, lastNinetyDayContributors.length];
  }

  collectRepoCommits(commitRepo: IRepo[]): any[] {
    const dataArray = commitRepo.map(repo => {
      return {
        number: repo.scmRevisionNumber.match(new RegExp('^.{0,7}'))[0],
        author: repo.scmAuthorLogin,
        message: repo.scmCommitLog,
        time: repo.scmCommitTimestamp,
        urlscm: repo.scmUrl
      };
    });
    return dataArray;
  }

  collectRepoCommitsForWeek(commitRepo: IRepoResult[]): any[] {
    var dataArrayofWeek = [];
    commitRepo.map(repo => {
      repo.data.forEach(commitData => {
        dataArrayofWeek.push({
          number: commitData.scmRevisionNumber.match(new RegExp('^.{0,7}'))[0],
          author: commitData.scmAuthorLogin,
          message: commitData.scmCommitLog,
          time: commitData.scmCommitTimestamp,
          urlscm: commitData.scmUrl
        })
      });
    });
    return dataArrayofWeek;
  }

  collectRepoPulls(pullRepo: IRepo[]): any[] {
    const dataArray = pullRepo.map(repo => {
      return {
        number: repo.number,
        author: repo.mergeAuthor,
        message: repo.scmCommitLog,
        time: repo.timestamp,
        pullstate: repo.state,
        urlscm: repo.scmUrl
      };
    });
    return dataArray;
  }

  collectRepoPullsForWeek(pullRepo: IRepoResult[]): any[] {
    var dataArrayofWeek = [];
    pullRepo.map(repo => {
      repo.data.forEach(pullData => {
        dataArrayofWeek.push({
          number: pullData.number,
          author: pullData.mergeAuthor,
          message: pullData.scmCommitLog,
          time: pullData.timestamp,
          pullstate: pullData.state,
          urlscm: pullData.scmUrl
        })
      });
    });
    return dataArrayofWeek;
  }

  collectRepoIssues(issueRepo: IRepo[]): any[] {
    const dataArray = issueRepo.map(repo => {
      return {
        number: repo.scmRevisionNumber,
        author: repo.userId,
        message: repo.scmCommitLog,
        time: repo.timestamp,
        urlscm: repo.scmUrl
      };
    });
    return dataArray;
  }

  collectRepoIssuesForWeek(issueRepo: IRepoResult[]): any[] {
    var dataArrayofWeek = [];
    issueRepo.map(repo => {
      repo.data.forEach(issueData => {
        dataArrayofWeek.push({
          number: issueData.scmRevisionNumber,
          author: issueData.userId,
          message: issueData.scmCommitLog,
          time: issueData.timestamp,
          urlscm: issueData.scmUrl
        });
      });
    });
    return dataArrayofWeek;
  }

  private collectDataArray(content: any[], componentId: string):[any, number] {
    const dataArrayToSend = [];
    let maxChartCount=0;
    content.sort((a, b) => {
      return a.time-b.time;
    });

    const groupedResults = groupBy(content, (result) => moment(new Date(result.time), 'DD/MM/YYYY').startOf('day'));
    for (const key of Object.keys(groupedResults)) {
      if(maxChartCount<groupedResults[key].length){
        maxChartCount = groupedResults[key].length;
      }
      dataArrayToSend.push(
        {
          name: new Date(key),
          value: groupedResults[key].length,
          data: groupedResults[key],
          componentId: componentId,
          collectorItemId: this.selectedCollectorItem.collectorItemId
        }
      );
    }
    return [dataArrayToSend, maxChartCount];
  }

  private collectDataArrayForWeek(content: any[], componentId: string, commitPullResult: any[],noOfDays):[any,number] {
    const dataArrayToSend = [];
    var count = 0;
    let maxChartCount=0;
    commitPullResult.map(countValue => {
      count += countValue.count;
      var data = [];
      for (let i = count - countValue.count; i < count; i++) {
        data.push(content[i]);
      }
      if(maxChartCount<countValue.count){
        maxChartCount = countValue.count;
      }
      dataArrayToSend.push({
        name: countValue.name,
        value: countValue.count,
        data: data,
        componentId: componentId,
        collectorItemId: this.selectedCollectorItem.collectorItemId
      });
    });

  if(noOfDays == 90){
    var check = false;
    dataArrayToSend.map(x=>{
      if((x.name-0) == 12){
          check = true;
      }
     })
    if(check){
   dataArrayToSend.map(x=>{
    if((x.name-0) < 4){
      x.name= (x.name-0) + 12;
    }
   })
   dataArrayToSend.sort((a, b) => {
    return a.name - b.name;
  });
   dataArrayToSend.map(x => {
    if((x.name-0) > 12){
      x.name = (x.name-0)%12;
    }
    x.name = (x.name).toString();
   })
  }
  else{
    dataArrayToSend.map(x => {
      x.name =(x.name).toString();
     })
  }
}
    
  return [dataArrayToSend, maxChartCount];
  }

  // *********************** TOTAL REPO COUNTS ************************
  generateTotalRepoCounts(commitResult: IRepo[], pullResult: IRepo[], issueResult: IRepo[]) {
    if (!commitResult || !pullResult || !issueResult) {
      return;
    }
    var commit = [];
    var pullComplete = [];
    var pullActive = [];
    var issue = [];

    for (let i = 0; i < this.TOTAL_REPO_COUNTS_TIME_RANGES.length; i++) {
      const currDate = this.toMidnight(new Date());
      if (i === 0) {
        currDate.setDate(currDate.getDate() - this.TOTAL_REPO_COUNTS_TIME_RANGES[0]);
        commit[0] = commitResult.filter(repo => this.checkRepoAfterDate(repo.scmCommitTimestamp, currDate)).length;
        pullComplete[0] = pullResult.filter(repo => this.checkRepoAfterDatewithStateComplete(repo.timestamp, currDate, repo.state)).length;
        pullActive[0] = pullResult.filter(repo => this.checkRepoAfterDatewithStateActive(repo.timestamp, currDate, repo.state)).length;
        issue[0] = issueResult.filter(repo => this.checkRepoAfterDate(repo.timestamp, currDate)).length;
      }else{
        currDate.setDate(currDate.getDate() - this.TOTAL_REPO_COUNTS_TIME_RANGES[i]+1);
        commit[i] = commitResult.filter(repo => this.checkRepoAfterDate(repo.scmCommitTimestamp, currDate)).length;
        pullComplete[i] = pullResult.filter(repo => this.checkRepoAfterDatewithStateComplete(repo.timestamp, currDate, repo.state)).length;
        pullActive[i] = pullResult.filter(repo => this.checkRepoAfterDatewithStateActive(repo.timestamp, currDate, repo.state)).length;
        issue[i] = issueResult.filter(repo => this.checkRepoAfterDate(repo.timestamp, currDate)).length;
      }
      
    }

    const commuters = this.collectContributorCount(commitResult, 'commit');
    const contributors = this.collectContributorCount(pullResult, 'pull');
  
    
    switch ((this.selectDays)) {
      case FIFTEEN:
        this.charts[1].data = commit[0].toString();
        this.charts[2].data = commit[1].toString();
        this.charts[3].data = commit[2].toString();
        this.charts[4].data = '';
        this.charts[5].data = pullComplete[0].toString();
        this.charts[6].data = pullComplete[1].toString();
        this.charts[7].data = pullComplete[2].toString();
        this.charts[8].data = '';
        this.charts[9].data = pullActive[0].toString();
        this.charts[10].data = pullActive[1].toString();
        this.charts[11].data = pullActive[2].toString();
        this.charts[12].data = '';
        this.charts[13].data = commuters[0].toString();
        this.charts[14].data = commuters[1].toString();
        this.charts[15].data = commuters[2].toString();
         this.charts[16].data = '';
        this.charts[17].data = contributors[0].toString();
        this.charts[18].data = contributors[1].toString();
        this.charts[19].data = contributors[2].toString();
         this.charts[20].data = '';
        break;

      case THIRTY:
        this.charts[1].data = commit[0].toString();
        this.charts[2].data = commit[1].toString();
        this.charts[3].data = commit[2].toString();
        this.charts[4].data = commit[3].toString();
        this.charts[5].data = pullComplete[0].toString();
        this.charts[6].data = pullComplete[1].toString();
        this.charts[7].data = pullComplete[2].toString();
        this.charts[8].data = pullComplete[3].toString();
        this.charts[9].data = pullActive[0].toString();
        this.charts[10].data = pullActive[1].toString();
        this.charts[11].data = pullActive[2].toString();
        this.charts[12].data = pullActive[3].toString();
        this.charts[13].data = commuters[0].toString();
        this.charts[14].data = commuters[1].toString();
        this.charts[15].data = commuters[2].toString();
        this.charts[16].data = commuters[3].toString();
        this.charts[17].data = contributors[0].toString();
        this.charts[18].data = contributors[1].toString();
        this.charts[19].data = contributors[2].toString();
        this.charts[20].data = contributors[3].toString();
        break;

      case SIXTY:
        this.charts[1].data = commit[0].toString();
        this.charts[2].data = commit[1].toString();
        this.charts[3].data = commit[2].toString();
        this.charts[4].data = commit[4].toString();
        this.charts[5].data = pullComplete[0].toString();
        this.charts[6].data = pullComplete[1].toString();
        this.charts[7].data = pullComplete[2].toString();
        this.charts[8].data = pullComplete[4].toString();
        this.charts[9].data = pullActive[0].toString();
        this.charts[10].data = pullActive[1].toString();
        this.charts[11].data = pullActive[2].toString();
        this.charts[12].data = pullActive[4].toString();
        this.charts[13].data = commuters[0].toString();
        this.charts[14].data = commuters[1].toString();
        this.charts[15].data = commuters[2].toString();
        this.charts[16].data = commuters[4].toString();
        this.charts[17].data = contributors[0].toString();
        this.charts[18].data = contributors[1].toString();
        this.charts[19].data = contributors[2].toString();
        this.charts[20].data = contributors[4].toString();
        break;

      case NINETY:
        this.charts[1].data = commit[0].toString();
        this.charts[2].data = commit[1].toString();
        this.charts[3].data = commit[2].toString();
        this.charts[4].data = commit[5].toString();
        this.charts[5].data = pullComplete[0].toString();
        this.charts[6].data = pullComplete[1].toString();
        this.charts[7].data = pullComplete[2].toString();
        this.charts[8].data = pullComplete[5].toString();
        this.charts[9].data = pullActive[0].toString();
        this.charts[10].data = pullActive[1].toString();
        this.charts[11].data = pullActive[2].toString();
        this.charts[12].data = pullActive[5].toString();
        this.charts[13].data = commuters[0].toString();
        this.charts[14].data = commuters[1].toString();
        this.charts[15].data = commuters[2].toString();
        this.charts[16].data = commuters[5].toString();
        this.charts[17].data = contributors[0].toString();
        this.charts[18].data = contributors[1].toString();
        this.charts[19].data = contributors[2].toString();
        this.charts[20].data = contributors[5].toString();

        break;

      default:
        this.charts[1].data = commit[0].toString();
        this.charts[2].data = commit[1].toString();
        this.charts[3].data = commit[2].toString();
         this.charts[4].data = '';
        this.charts[5].data = pullComplete[0].toString();
        this.charts[6].data = pullComplete[1].toString();
        this.charts[7].data = pullComplete[2].toString();
         this.charts[8].data = '';
        this.charts[9].data = pullActive[0].toString();
        this.charts[10].data = pullActive[1].toString();
        this.charts[11].data = pullActive[2].toString();
        this.charts[12].data = '';
        this.charts[13].data = commuters[0].toString();
        this.charts[14].data = commuters[1].toString();
        this.charts[15].data = commuters[2].toString();
         this.charts[16].data = '';
        this.charts[17].data = contributors[0].toString();
        this.charts[18].data = contributors[1].toString();
        this.charts[19].data = contributors[2].toString();
         this.charts[20].data = '';
        break;
    }
  }

  //// *********************** HELPER UTILS *********************
  private toMidnight(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }


  private checkRepoAfterDate(repoTime: string, date: Date): boolean {
    return new Date(repoTime) >= date;
  }

  private checkRepoAfterDatewithStateComplete(repoTime: string, date: Date, repoPullState: string): boolean {
    if (repoPullState === 'completed') {
      return new Date(repoTime) >= date;
    }
  }

  private checkRepoAfterDatewithStateActive(repoTime: string, date: Date, repoPullState: string): boolean {
    if (repoPullState === 'active') {
      return new Date(repoTime) >= date;
    }
  }

  setDefaultIfNoData() {
    this.charts[0].data.dataPoints[0].series = [{ name: new Date(), value: 0, data: 'Commits' }];
    this.charts[0].data.dataPoints[1].series = [{ name: new Date(), value: 0, data: 'Pulls' }];
    this.charts[1].data = '0';
    this.charts[2].data = '0';
    this.charts[3].data = '0';
    this.charts[5].data = '0';
    this.charts[6].data = '0';
    this.charts[7].data = '0';
    this.charts[9].data = '0';
    this.charts[10].data = '0';
    this.charts[11].data = '0';
    this.charts[13].data = '0';
    this.charts[14].data = '0';
    this.charts[15].data = '0';
    this.charts[17].data = '0';
    this.charts[18].data = '0';
    this.charts[19].data = '0';
    super.loadComponent(this.childLayoutTag);
  }

  setDefaultIfNoDataWithDays() {
      this.charts[0].data.dataPoints[0].series = [{ name: new Date(), value: 0, data: 'Commits' }];
      this.charts[0].data.dataPoints[1].series = [{ name: new Date(), value: 0, data: 'Pulls' }];
      this.charts[1].data = '0';
      this.charts[2].data = '0';
      this.charts[3].data = '0';
      this.charts[4].data = '0';
      this.charts[5].data = '0';
      this.charts[6].data = '0';
      this.charts[7].data = '0';
      this.charts[8].data = '0';
      this.charts[9].data = '0';
      this.charts[10].data = '0';
      this.charts[11].data = '0';
      this.charts[12].data = '0';
      this.charts[13].data = '0';
      this.charts[14].data = '0';
      this.charts[15].data = '0';
      this.charts[16].data = '0';
      this.charts[17].data = '0';
      this.charts[18].data = '0';
      this.charts[19].data = '0';
      this.charts[20].data = '0';
    super.loadComponent(this.childLayoutTag);
  }


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleSelection(branch: string) {
    if (this.isLoading) return;

    this.isLoading = true;
    const selected = this.selectedBranches;
    if (selected.includes(branch)) {
      if(selected.length>1)
        this.branches.setValue(selected.filter(b => b !== branch));
    } else {
      this.branches.setValue([...selected, branch]);
    }
    this.startRefreshInterval();
    
  }

}


