import { Injectable } from "@angular/core";
import { IApiResponse } from "./IHttpInterceptor.interface";

@Injectable({
	providedIn: 'root'
})
export class CacheService {
    private cache: Map<string, IApiResponse> = new Map();
    constructor() { }

    set(key: string, value: IApiResponse) {
        this.cache.set(key, value);
    }

    get(key: string): IApiResponse {
        return this.cache.get(key);
    }

    // Method to clear the cache
    clearCache(): void {
        this.cache?.clear();
    }
}