<div>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Edit Dashboard</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <ul class="nav nav-tabs custom-tabs">
            <li class="auth-modal-tab" *ngFor="let tab of tabs; let i=index" (click)="tabToggleView(i)" [ngClass]="tab.name == tabView ? 'active' : ''">
              <a data-toggle="tab">{{tab.name}}</a>
            </li>
          </ul>
  
          <div class="container-fluid" *ngIf="tabView == 'Widget Management'">
            <div class="row">
              <div class="col-md-12">
                <label class="modal-label">Add/Remove Widgets</label>
                <form name="editForm">
                  <ul *ngIf="widgetSelections" class="list-animate list-group text-left">
                    <ng-container *ngFor="let title of Object.keys(widgetSelections); let i=index">
                      <ng-container *ngIf="title!='product' && title!='monitor2' && title!='undefined' && title!=excludeTitle">
                        <li class="list-group-item">
                          <span class="fa fa-lg left-icon"></span>
                          <input type="checkbox" name="widgetSelections{{i}}" [disabled]="selectWidgetsDisabled" [(ngModel)]="widgetSelections[title]">
                          <span> {{ title | uppercase}}</span>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col text-center">
          <button type="button" class="btn btn-outline-secondary mr-2" (click)="activeModal.dismiss()">Cancel</button>
          <button type="button" class="btn btn-primary btn-wide" (click)="saveForm()">Save</button>
        </div>
      </div>
    </div>
  </div>
  