import {Component,ViewEncapsulation,Input,ComponentFactoryResolver,ChangeDetectorRef, OnInit, Type, ViewChild} from '@angular/core';
import {DashboardService} from "../../../shared/dashboard.service"
import { CollectorService } from "../../../shared/collector.service"
import {RepoService} from "../repo.service"
import { Router, ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WidgetComponent } from 'src/app/shared/widget/widget.component';
import { IRepo } from '../interfaces';
import { IBarChartRepoItem } from 'src/app/shared/charts/vertical-bar-chart/vertical-bar-chart-interfaces';
import { SharedDataService } from '../shared-data.service';
import { PULLS } from 'src/app/shared/app-constants';
import { IDateSelectionCallbacks } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-repo-detail',
  templateUrl: './repo-detail.component.html',
  styleUrls: ['./repo-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RepoDetailComponent  extends WidgetComponent  implements OnInit {
  @Input() detailView: Type<any>;
  @Input() authors: any;
  
  
  public data: IBarChartRepoItem;
  private collectorItemId;
  storiesData;
  filterdData;
  selectedAuthor;
  selectedPullRequestStatus;
  startTime;
  endTime;
  page = 1;
  pageSize = 10;
  collectionSize;
  minDate = new Date(2022, 0, 3); 
  maxDate = new Date();
  pullRequestStatuses;
  componentId;
  showCount;
  countData;
  countCollectionSize;
  configfetchCount = true
  multipleBranchSelection: any[] = [];
  noOfDays: number=0;
  rangeDates: Date[] = [];
  @ViewChild('calendar', {}) private calendar: any;


  constructor(componentFactoryResolver: ComponentFactoryResolver,
    cdr: ChangeDetectorRef,
    dashboardService: DashboardService,
    private repoService: RepoService,
    public router: Router,


    public route: ActivatedRoute,
    private sharedDataService: SharedDataService) {
    super(componentFactoryResolver, cdr, dashboardService);
  }

  ngOnInit() {
    this.sharedDataService.multipleSelection$.subscribe((newSelection) => {
      this.multipleBranchSelection = newSelection;
    });
    this.sharedDataService.updateDate$.subscribe((newSelection) => {
      this.noOfDays = newSelection;
    });
    if(this.showCount){
      this.fetchDataWithDefaultDateRange();
    } else {
      this.storiesData = this.data
      this.filterdData = this.data;
      this.collectionSize = this.filterdData?.length;
    }

    const currentYear = new Date().getFullYear();

    const currentData = new Date().getDate()

    const currentMonth = new Date().getMonth();

    this.minDate = new Date(currentYear - 0, currentMonth - 3, currentData);
  }

  fetchDataWithDefaultDateRange(){
    this.startTime = new Date(new Date().getTime()-((this.noOfDays-1)*24*60*60*1000)).toLocaleDateString("en-US")
    this.endTime =   new Date().toLocaleDateString("en-US");
    this.fetchCodeCountData();
  }

  handleCalenderData(): IDateSelectionCallbacks {
    return {
      onDateSelection: (dateRange) => {
        if(dateRange?.length===2){
           this.rangeDates = dateRange    
            this.onDateRangeSelection();
          }
        },
      clearMethod :(dateRange)=> {
        this.rangeDates = dateRange
        this.onCalendarClear()
      }
    }
  }

  private onDateRangeSelection() {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const startDate = this.rangeDates[0] && new Date(this.rangeDates[0]).toLocaleDateString('en-US', options);
    const endDate = this.rangeDates[1] && new Date(this.rangeDates[1]).toLocaleDateString('en-US', options);
  
    if (startDate && endDate) {
      this.filterDataWithDate(startDate, endDate);
      if (this.calendar) {
        this.calendar.overlayVisible = false;
      }
    }
  }

  private onCalendarClear() {
    this.fetchDataWithDefaultDateRange();
  }

  @Input()
  set detailData(data: any) {
    if(data.showCount){
      this.showCount = data.showCount;
      this.componentId = data.componentId
      this.collectorItemId = data.collectorItemId;
      
    }
    else {
      this.data = data.data;
      this.componentId = data.componentId
      this.collectorItemId = data.collectorItemId;
      const allAuthors = [...new Set(data.data.map(item => item.author))]; // [ 'A', 'B']
      this.authors = allAuthors;
      this.pullRequestStatuses = [...new Set(data.data.map(item => item.pullstate))].filter(eachItem => eachItem)
    }
    if(data.filterBy != undefined){
    this.configfetchCount = data.filterBy === PULLS ? false: true;
    }
    else{
    this.configfetchCount = this.pullRequestStatuses?.length > 0 ? false : true;
    }
  }

  handleChangeSearchOption(value){
    this.configfetchCount = value
    this.fetchCodeCountData()
  }

  onPullRequestStatusSelected(){
    this.filterAllData()
    this.collectionSize = this.filterdData?.length;
  }

  collectRepoCommits(commitRepo: IRepo[]): any[] {
    const commitDataArray = commitRepo.map(repo => {
      return {
        number: repo.scmRevisionNumber.match(new RegExp('^.{0,7}'))[0],
        author: repo.scmAuthorLogin,
        message: repo.scmCommitLog,
        time: repo.scmCommitTimestamp,
        urlscm: repo.scmUrl
      };
    });
    return commitDataArray;
  }


  collectRepoPulls(pullRepo: IRepo[]): any[] {
    const pullDataArray = pullRepo.map(repo => {

      return {
        number: repo.number,
        author: repo.mergeAuthor,
        message: repo.scmCommitLog,
        time: repo.timestamp,
        pullstate: repo.state,
        urlscm: repo.scmUrl
      };
    });

    return pullDataArray;
  }

  filterAllData(){
      let filtered = false;
      this.filterdData = this.storiesData

      if(!!this.selectedAuthor){
          this.filterdData = this.storiesData.filter(eachData => eachData.author === this.selectedAuthor)
          filtered = true
      }
    
      if(this.selectedPullRequestStatus && this.selectedPullRequestStatus !== "All"){
        this.filterdData = filtered ? this.filterdData.filter(eachData => eachData.pullstate === this.selectedPullRequestStatus) :  this.storiesData.filter(eachData => eachData.pullstate === this.selectedPullRequestStatus)
        filtered = true
      }
      this.authors = [...new Set(this.filterdData.map(item => item.author))];
      this.pullRequestStatuses = this.pullRequestStatuses && [...new Set(this.filterdData.map(item => item.pullstate))].filter(eachItem => eachItem)
      this.collectionSize = this.filterdData?.length;
  }

  onAuthorSelected(event){
    this.selectedAuthor = event.target.value;

    this.filterAllData()
    this.collectionSize = this.filterdData?.length;
  }

  filterDataWithDate(startTime, endTime){
    this.startTime = startTime;
    this.endTime = endTime;
    this.startRefreshInterval()
    this.collectionSize = this.filterdData?.length;
  }

  

  startRefreshInterval() {
         const response =  this.repoService.fetchCodeDataWithFilter(this.componentId,  this.startTime, this.endTime, this.configfetchCount,this.multipleBranchSelection, this.collectorItemId).pipe(catchError(err => of(err)))
        response.subscribe((data) => {
          this.storiesData = !this.configfetchCount ? this.collectRepoPulls(data) : this.collectRepoCommits(data) 
          this.authors = [...new Set(this.storiesData.map(item => item.author))]
          this.pullRequestStatuses = data && data?.length > 0 && this.pullRequestStatuses?.length >0 ? [...new Set(this.storiesData.map(item => item.pullstate))] : this.pullRequestStatuses
          this.filterAllData()
        })
  }

  fetchCodeCountData(){
    const response = this.repoService.fetchCodeDataCount(this.componentId,this.startTime, this.endTime, this.configfetchCount,this.multipleBranchSelection, this.collectorItemId) 
    response.subscribe((data) => {
      this.countData = data;
      this.countCollectionSize = data?.length;
    })
  }

}
