import { Component, Input } from '@angular/core';
import { ITableData } from './table-view-interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CLOSE, STORY_POINTS } from '../../app-constants';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { QaMetricsDataSharedService } from 'src/app/product_widget_modules/qa-metrics/qa-metrics-data-shared.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent {
  readonly CLOSE = CLOSE;
  @Input() data: ITableData;
  @Input() title: string;
  @Input() modal:boolean = false;
  @Input() customSort: (event: SortEvent)=> any;
  @Input() redirection: (id: any, name: string)=> any;
  REPO : string = 'repo'
  TYPE : string = 'type'
  SNAPSHOT: string = 'lastSnapshotDate'
  
  DEFAULT_SCROLL_HEIGHT: string = "250px";

  constructor(public activeModal: NgbActiveModal,
    private qaMetricsDataService: QaMetricsDataSharedService,
    private router: Router){
  }

  handleSortFunction(event: any) {
    if (this.data.sortable && this.customSort) {
      this.customSort(event);
    }
  }

  redirectionToProductDashboard(id: any, name: string) {
    this.redirection(id, name);
  }

  compareTargettoThreshold(data:any){
    let value = Number(data.substring(0, (data.length - 1)));
    if(value >= this.data?.target){
      return true;
    }else{
      return false
    }
  }
  isStoryPointsData(data: any){
  return data === STORY_POINTS ? true: false
}

onClickFunction(rowData,col){
  this.qaMetricsDataService.triggerEscapeDefectsBugs(rowData,col)
}

}
