import { Injectable } from "@angular/core"
import { IApiResponse, IHttpInterceptor } from "./IHttpInterceptor.interface"
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http"
import { Observable } from "rxjs/internal/Observable"
import { of } from "rxjs/internal/observable/of"
import { share } from "rxjs/internal/operators/share"
import { tap } from "rxjs/internal/operators/tap"
import { catchError } from "rxjs/internal/operators/catchError"
import { environment } from 'src/environments/environment';
import { API_HEADER_NO_CACHED, API_CACHE_TTL, API_TYPE_GET } from "../shared/app-constants"
import { CacheService } from "./cache-service.service"

@Injectable()
export class HttpCallsInterceptor implements IHttpInterceptor {
  //cache: Map<string, IApiResponse> = new Map();
  constructor(private cacheService:CacheService ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    
    const envCacheTTL = environment.cacheTTL;
    const API_KEY:string = req.urlWithParams;
    if(!envCacheTTL || req.method !== API_TYPE_GET || req.params?.get(API_HEADER_NO_CACHED)) {
        return next.handle(req)
    }
    const cachedResponse: IApiResponse = this.cacheService.get(API_KEY);
    if(cachedResponse && cachedResponse.ttl.getTime() >= new Date().getTime()) {
        return of(cachedResponse.response.clone());
    } else {
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    const cacheTTL = Number(req.params.get(API_CACHE_TTL) || envCacheTTL);
                    const ttl = new Date(Date.now() + (cacheTTL * 60 * 1000));
                    this.cacheService.set(API_KEY, {response: evt.clone(), ttl });
                }
            }),
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    console.log(err)
                }
                return Observable.throw(err); 
            }),            
            share()
        )
    }
  }
}