<div class="tree-table-view">
    <div class="header-view" *ngIf="title || subTitle">
        <div class="title-section">
            <span class="title" *ngIf="title">{{title}}</span>
            <span class="sub-title" *ngIf="subTitle">{{subTitle}}</span>
        </div>
    </div>
    <p-treeTable 
        *ngIf="data?.tableColumns && data?.tableData?.length else msg_block" 
        [value]="data.tableData" [columns]="data.tableColumns"
        >
        <ng-template pTemplate="header" let-columns>
            <tr>                                                 
                <th *ngFor="let col of columns; let i = index" [id]="i"
                    [class.text-center]="col?.align === 'center'" [ttSortableColumn]="col.field">
                    <span *ngIf="col.header?.twoLines else singleLine">{{ col.header.textHeaders[0] }}<br>{{ col.header.textHeaders[1] }}</span> 
                    <ng-template #singleLine>
                        <span>{{ col.header }}</span>
                    </ng-template>
                    <p-treeTableSortIcon [field]="col.field" />
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr [ttRow]="rowNode">
                <td *ngFor="let col of columns; let i = index">
                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0" />
                    <ng-container *ngIf="rowData[col.field]?.period else fieldVal" >
                        <a href="javascript:void(0)" (click)="onLinkItemClick(rowData[col.field]?.id,  rowData[col.field]?.name)">
                            {{ rowData[col.field].name }}
                        </a>
                    </ng-container>
                    <ng-template #fieldVal>
                        {{ rowData[col.field] }}
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-treeTable>
    <ng-template #msg_block>
        <span class="msg_block">{{data?.loadingMessage}}</span>
    </ng-template>
</div>
