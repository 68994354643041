import { Component, OnDestroy, OnInit } from '@angular/core';
import { SeDashboardMetricsService } from './se-metrics.service';
import { ITableData } from 'src/app/shared/tables/table-view/table-view-interface';
import { API_PARAM_PAGE, API_PARAM_SIZE, NO_DATA_AVAILABLE, REPO, SE_METRICS, HYPHEN, PERCENTAGE_SIGN, NAN, DASHBOARD_EMD, DASHBOARD_CCT, DASHBOARD_Admin_Portal, DASHBOARD_AppRuntime, DASHBOARD_First_Mile_Customer_Support, DASHBOARD_Global_Design_System, DASHBOARD_Notifications_Framework, DASHBOARD_Receipt_Scanning, DASHBOARD_PSD, DSX_PROGRAM, SINGLE_QUOTES, DASHBOARD_D2C, DASHBOARD_DTV, DASHBOARD_DX, DASHBOARD_Virtual_Control_Room, PROGRAM, NAME, TIME_UNIT_MS, SE_METRICS_DIALOGUE_TEST, DEPLOYNMENT_FREQUENCY, AUTOMATION_TEXT_COVERAGE, UNIT_TEXT_COVERAGE, APPLICATION_RESPONSE_TIME, REUSABILITY, AUTOMATION_TEXT_COVERAGE_TITLE, UNIT_TEXT_COVERAGE_TITLE, APP_RESPONSE_TIME_TITLE, REUSABILITY_TITLE, AVAILABILITY_TITLE, AVAILABILITY_TABLE_INFO, LEAD_TIME_FIELD, DAY, DAYS, LEAD_TIME_TITLE, LEAD_TIME_INFO_CONTENT, DEPLOYMENT_FREQUENCY, CHANGE_FAIL_RATE, CHANGE_FAIL_RATE_INFO, MEAN_TIME_TO_RESOLVE, MEAN_TIME_TO_RESOLVE_INFO, TIME_UNIT_HR, TIME_UNIT_HRS, DASHBOARD_WALMART_LUMINATE, DASHBOARD_B2B, DASHBOARD_GNRM, DASHBOARD_GUIDED_SELLING, DASHBOARD_RETAIL_360, DASHBOARD_WALMART_DSD, DEPLOYMENT_FREQUENCY_KEY, TEST_AUTOMATION_KEY, UNIT_TEST_COVERAGE_KEY, APP_RESPONSE_TIME_KEY, REUSABILITY_KEY, LEAD_TIME_TO_CHANGE_KEY, CHANGE_FAIL_RATE_KEY, CONSUMER, SUPPLY_CHAIN, DSX, SUPPLY_CHAIN_TITLE, CONSUMER_TITLE, DSX_TITLE, MANUAL_TEST_SUMMARY, MANUAL, AUTOMATIC, ASTERIX, KEY_ART_END_USER, KEY_ART_OVERALL, COMMERCIAL_AND_CONSUMER_TITLE, COMMERCIAL_AND_CONSUMER, NUMBER, DASHBOARD_DTC, LABEL_B2B_PROGRAMS, LABEL_DTC_PROGRAMS } from '../shared/app-constants';
import { Observable, Subscription, of } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { DashboardListService } from '../landing_page/dashboard-list/dashboard-list.service';
import { IDashboards } from '../landing_page/dashboard-list/dashboard-list';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IKpiDialogInfo } from './se-metrics.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SeMetricsManualDataFormComponent } from './se-metrics-manual-data-form/se-metrics-manual-data-form.component';
import { getMonthOptions, getWikiLink, getYearOptions } from '../shared/app-utility';
@Component({
  selector: 'app-se-metrics',
  templateUrl: './se-metrics.component.html',
  styleUrls: ['./se-metrics.component.scss']
})
export class SEMetricsComponent implements OnInit, OnDestroy {
  monthOptions$: Observable<{ value: number, label: string }[]>;
  selectedMonth: any;
  month: number;
  year: number;
  noDataAvailable: boolean;
  dsxKpisNumbers = {}; 
  dsxRowData = {
    'repo': {
      name: DSX_PROGRAM,
      period: HYPHEN,
      id: HYPHEN
    }
  }
  public SeMetricsMessage = SE_METRICS;
  public noDataMessage = NO_DATA_AVAILABLE;
  private productDashboards: IDashboards[] = [];
  private readonly KPI_DEPLOYMENT = 'Deployment Frequency<\>(PER MONTH)';
  private readonly KPI_AUTOMATION = 'Automation Test<\>Coverage (%)';
  private readonly KPI_UNIT_TEST = 'Unit Test<\>Coverage (%)';
  private readonly KPI_APP_RESPONSE = 'App Response<\>Time (Milli seconds)';
  private readonly KPI_SOFTWARE_REUSABILITY = 'Software<\>Reusability (%)';
  private readonly KPI_AVAILABILITY = 'Availability<\>(%)';
  private readonly KPI_LEAD_TIME = 'Lead Time To <\>Change (Days)';
  private readonly KPI_CHANGE_FAIL_RATE = 'Change Fail <\>Rate (%)';
  private readonly KPI_MEAN_TIME = 'Mean time to <\>resolve (Hours)';
  protected wikiLink ;
  getYearOptions() { return getYearOptions(); };
  selectedTab: string = 'tabSeMetrics';

  categoryProgramList: { title: string, data: ITableData }[] = [
    { title: COMMERCIAL_AND_CONSUMER_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
    { title: SUPPLY_CHAIN_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
    { title: DSX_TITLE, data: { tableData: [], tableColumns: [], scrollable: false } },
  ];

  private readonly EXTRA_PROGRAMS = [
    DASHBOARD_D2C,
    DASHBOARD_DX,
    DASHBOARD_WALMART_LUMINATE
  ]

  protected isDialogOpen = false;
  protected KpiDialogInfos: IKpiDialogInfo[] = [
    {
      title: DEPLOYMENT_FREQUENCY,
      info: DEPLOYNMENT_FREQUENCY
    },
    {
      title: AUTOMATION_TEXT_COVERAGE_TITLE,
      info: AUTOMATION_TEXT_COVERAGE
    },
    {
      title: UNIT_TEXT_COVERAGE_TITLE,
      info: UNIT_TEXT_COVERAGE
    },
    {
      title: APP_RESPONSE_TIME_TITLE,
      info: APPLICATION_RESPONSE_TIME
    },
    {
      title: REUSABILITY_TITLE,
      info: REUSABILITY
    },
    {
      title: AVAILABILITY_TITLE,
      info: AVAILABILITY_TABLE_INFO
    },
    {
      title: LEAD_TIME_TITLE,
      info: LEAD_TIME_INFO_CONTENT
    },
    {
      title: CHANGE_FAIL_RATE,
      info: CHANGE_FAIL_RATE_INFO
    },
    {
      title: MEAN_TIME_TO_RESOLVE,
      info: MEAN_TIME_TO_RESOLVE_INFO
    },
  ]

  protected readonly DIALOG_TITLE_TEXT = SE_METRICS_DIALOGUE_TEST;

  openDialog() {
    this.isDialogOpen = true;
  }

  closeDialog() {
    this.isDialogOpen = false;
  }

  private fragmentSubscription: Subscription;

  constructor(private seMetricesService: SeDashboardMetricsService, private dashboardListService: DashboardListService,
    private router: Router, private route: ActivatedRoute, private modalService: NgbModal) {
  }

  addManualData(){
    const modalRef = this.modalService.open(SeMetricsManualDataFormComponent);
    modalRef.componentInstance.modalInstance = modalRef;
  }

  ngOnInit(): void {
    this.wikiLink = getWikiLink();
    const currentDate = new Date();
    this.month = currentDate.getMonth();
    this.year = currentDate.getFullYear();
    this.monthOptions$ = of(getMonthOptions());
    this.selectedMonth = currentDate.getMonth();
    this.categoryProgramList.forEach(category => {
      category.data.sortable = true;
    });
    this.fetchProductDashboards();

    this.fragmentSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.route.snapshot.fragment;
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }


  onMonthChange(event) {
    this.month = event.target.value;
    this.fetchKPIsData();
    this.dsxKpisNumbers = {};
    this.dsxRowData = {
      'repo': {
        name: DSX_PROGRAM,
        period: HYPHEN,
        id: HYPHEN
      }
    }
  }

  onYearChange(event) {
    this.year = new Date().getFullYear();
    this.fetchKPIsData();
  }

  private fetchKPIsData() {
    if (this.productDashboards.length) {
      this.setTableData(this.productDashboards);
    } else {
      this.fetchProductDashboards();
    }
  }


  private fetchProductDashboards() {
    const params = this.paramBuilder(0, '10');
    this.dashboardListService.fetchProductDashboards(params).subscribe(
      response => {
        if (response?.data) {
          this.productDashboards = response.data.sort((a, b) => { return a.name.toUpperCase().localeCompare(b.name.toUpperCase()) });
          this.setTableData(this.productDashboards)
        }
      },
      error => console.log(error)
    );
  }

  fetchSeMetrics() {
    this.seMetricesService.fetchSeMetrics(this.month, this.year)
      .subscribe(
        (data) => {
          if (data && data.length > 0) {
            this.setTableData(data)
            this.noDataAvailable = false;
          }
          else {
            this.noDataAvailable = true;
          }
        },
        (error) => {
          console.error('Error fetching data:', error);
          this.noDataAvailable = true;
        }
      );
  }

  paramBuilder(page: number, pageSize: string): HttpParams {
    return new HttpParams()
      .set(API_PARAM_PAGE, page.toString())
      .set(API_PARAM_SIZE, pageSize)
  }

  async setTableData(prdoductDashbords) {
    const KPI_NAMES = [this.KPI_DEPLOYMENT,
                        this.KPI_AUTOMATION,
                        this.KPI_UNIT_TEST,
                        this.KPI_APP_RESPONSE,
                        this.KPI_SOFTWARE_REUSABILITY,
                        this.KPI_LEAD_TIME,
                        this.KPI_AVAILABILITY,
                        this.KPI_CHANGE_FAIL_RATE,
                        this.KPI_MEAN_TIME
                      ];
    const filteredKPI_NAMES = KPI_NAMES.filter(eachName => eachName !== environment.hideSEMetricsColumn);
    const tableColumns: any[] = [{ field: REPO.toLowerCase(), header: { twoLines: true, textHeaders: [PROGRAM.toUpperCase(), NAME.toUpperCase()] } }];
    filteredKPI_NAMES.forEach(kpiName => {
      const textHeaders: string[] = kpiName.toUpperCase().split('<\>')
      tableColumns.push({
        field: kpiName,
        header: {
          twoLines: true,
          textHeaders
        }
      });
    });
    const commercialAndConsumerData = [];
    const supplyChainData = [];
    const dsxData = [];
    if (prdoductDashbords?.length) {

      (async () => {
        await this.processDashboard(prdoductDashbords, filteredKPI_NAMES, commercialAndConsumerData, supplyChainData, dsxData);
      })().then(() => {
        if (Object.keys(this.dsxKpisNumbers).length) {
          filteredKPI_NAMES.forEach((kpiName, count) => {
            if (this.dsxRowData[kpiName]) {
              switch (count) {
                case 0:
                  this.dsxRowData[kpiName] = Math.round(parseFloat(this.dsxRowData[kpiName]) / this.dsxKpisNumbers[kpiName]) + SINGLE_QUOTES;
                  break;
                case 3:
                  this.dsxRowData[kpiName] = Math.round(parseFloat(this.dsxRowData[kpiName].replace(/ ms?/, '')) / this.dsxKpisNumbers[kpiName]) + TIME_UNIT_MS;
                  break;
                case 5:
                  this.dsxRowData[kpiName] = Math.round(parseFloat(this.dsxRowData[kpiName].replace(/ days?/, '')) / this.dsxKpisNumbers[kpiName]) + DAYS;
                  break;
                case 8:
                  this.dsxRowData[kpiName] = Math.round(parseFloat(this.dsxRowData[kpiName].replace(/ hrs?/, '')) / this.dsxKpisNumbers[kpiName]) + TIME_UNIT_HRS;
                  break;
                default:
                  this.dsxRowData[kpiName] = Number(parseFloat(this.dsxRowData[kpiName]) / this.dsxKpisNumbers[kpiName]).toFixed(1) + PERCENTAGE_SIGN;
              }
              if (this.dsxRowData[kpiName].includes(NAN)) {
                this.dsxRowData[kpiName] = HYPHEN
              }
            } else {
              this.dsxRowData[kpiName] = HYPHEN
            }
          });
          // commercialData.push(this.dsxRowData)  (we are not displaying aggregated values of DSX on se metrics table)
        }
        this.setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns);

      }).catch(error => {
        console.error(error);
      });

      this.setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns);
    }
  }

  async processDashboard(prdoductDashbords, KPI_NAMES, commercialAndConsumerData, supplyChainData, dsxData) {
    const promises = [];
    const commercialAndConsumerRowData = [];
    for (const dashboard of prdoductDashbords) {
      const rowData = {
        'repo': {
          name: dashboard.name,
          period: HYPHEN,
          id: dashboard.id
        }
      };
      const combinedDataPromise = this.seMetricesService.fetchSeMetricsOverallData(dashboard.id, this.month, this.year).toPromise()
        .then((combinedResp) => {
          let testAutomationResp, mttrResp, appResponseTimeResp, availabilityResp, unitTestCoverageResp, reusabilityResp, dfCfrResp, leadTimeToChangeResp;

          if (combinedResp) {
              const chooseResponse = (manualData, automaticData) => {
                  return manualData != null 
                      ? { ...manualData, respType: MANUAL }
                      : { ...automaticData, respType: AUTOMATIC };
              };
          
              testAutomationResp = chooseResponse(
                combinedResp.manual?.TEST_AUTOMATION,
                combinedResp.automatic?.TEST_AUTOMATION
            );
        
            mttrResp = chooseResponse(
                combinedResp.manual?.MEAN_TIME_TO_RESOLVE != null
                    ? { mttr: combinedResp.manual.MEAN_TIME_TO_RESOLVE }
                    : null,
                combinedResp.automatic?.MEAN_TIME_TO_RESOLVE 
                    ? { mttr: combinedResp.automatic.MEAN_TIME_TO_RESOLVE }
                    : null
            );
        
            appResponseTimeResp = chooseResponse(
                combinedResp.manual?.APP_RESPONSE_TIME,
                combinedResp.automatic?.APP_RESPONSE_TIME
            );
        
            availabilityResp = chooseResponse(
                combinedResp.manual?.AVAILABILITY != null
                    ? { availability: combinedResp.manual.AVAILABILITY }
                    : null,
                combinedResp.automatic?.AVAILABILITY 
                    ? { availability: combinedResp.automatic.AVAILABILITY }
                    : null
            );
        
            unitTestCoverageResp = chooseResponse(
                combinedResp.manual?.UNIT_TEST_COVERAGE,
                combinedResp.automatic?.UNIT_TEST_COVERAGE
            );
        
            reusabilityResp = chooseResponse(
                combinedResp.manual?.REUSABILITY,
                combinedResp.automatic?.REUSABILITY
            );
        
            dfCfrResp = chooseResponse(
                combinedResp.manual?.DF_AND_CFR,
                combinedResp.automatic?.DF_AND_CFR
            );
        
            leadTimeToChangeResp = chooseResponse(
                combinedResp.manual?.LEAD_TIME_TO_CHANGE  != null
                    ? { leadTimeChange: combinedResp.manual.LEAD_TIME_TO_CHANGE }
                    : null,
                combinedResp.automatic?.LEAD_TIME_TO_CHANGE != null
                    ? { leadTimeChange: combinedResp.automatic.LEAD_TIME_TO_CHANGE }
                    : null
            );
          }
          
              if(testAutomationResp && testAutomationResp?.totalTestCasesCount != null && testAutomationResp?.automatedTestCasesCount != null){
                rowData[KPI_NAMES[1]] = testAutomationResp?.totalTestCasesCount == 0 ? Number(0)?.toFixed(2) + PERCENTAGE_SIGN: Number(testAutomationResp.automatedTestCasesCount / testAutomationResp.totalTestCasesCount * 100).toFixed(2) + PERCENTAGE_SIGN;
                if (rowData[KPI_NAMES[1]]) {
                  if (environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                    this.dsxKpisNumbers[KPI_NAMES[1]] = (this.dsxKpisNumbers[KPI_NAMES[1]] + 1) || 1;
                    this.dsxRowData[KPI_NAMES[1]] = parseFloat(rowData[KPI_NAMES[1]].replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) + (parseFloat(this.dsxRowData[KPI_NAMES[1]]?.replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) || 0) + PERCENTAGE_SIGN;
                  }
                }
                rowData[KPI_NAMES[1]] = this.getPrefix(testAutomationResp)+rowData[KPI_NAMES[1]]
              } else {
                rowData[KPI_NAMES[1]] = testAutomationResp?.totalTestCasesCount==0 && testAutomationResp?.automatedTestCasesCount==0 ? 0 : HYPHEN;
              }

              if(unitTestCoverageResp && unitTestCoverageResp?.value !== NAN){
                rowData[KPI_NAMES[2]] = Number(unitTestCoverageResp?.value)?.toFixed(2) + PERCENTAGE_SIGN;
                if (environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[KPI_NAMES[2]] = (this.dsxKpisNumbers[KPI_NAMES[2]] + 1) || 1;
                  this.dsxRowData[KPI_NAMES[2]] = parseFloat(rowData[KPI_NAMES[2]].replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) + (parseFloat(this.dsxRowData[KPI_NAMES[2]]?.replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) || 0) + PERCENTAGE_SIGN;
                }
                rowData[KPI_NAMES[2]] = this.getPrefix(unitTestCoverageResp)+rowData[KPI_NAMES[2]];
              } else {
                rowData[KPI_NAMES[2]] = HYPHEN;
              }

              if(appResponseTimeResp && (appResponseTimeResp[KEY_ART_END_USER] != null || appResponseTimeResp[KEY_ART_OVERALL] != null)){
                const appresponseTime = appResponseTimeResp[KEY_ART_END_USER] || appResponseTimeResp[KEY_ART_OVERALL];
                rowData[KPI_NAMES[3]] = appresponseTime >= 0 ? appresponseTime + TIME_UNIT_MS : HYPHEN;
                if (rowData[KPI_NAMES[3]] != HYPHEN && environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                    this.dsxKpisNumbers[KPI_NAMES[3]] = (this.dsxKpisNumbers[KPI_NAMES[3]] + 1) || 1;
                    this.dsxRowData[KPI_NAMES[3]] = parseFloat(rowData[KPI_NAMES[3]]) + (parseFloat(this.dsxRowData[KPI_NAMES[3]]) || 0) + SINGLE_QUOTES;
                  }
                rowData[KPI_NAMES[3]] = this.getPrefix(appResponseTimeResp)+rowData[KPI_NAMES[3]];
              } else {
                rowData[KPI_NAMES[3]] = HYPHEN;
              }

              if(reusabilityResp?.totalStoryPoints || reusabilityResp?.totalStoryPoints == 0) {
                rowData[KPI_NAMES[4]] = reusabilityResp.totalStoryPoints !== 0 
                                        ? Number(reusabilityResp.globalStoryPoints / reusabilityResp.totalStoryPoints * 100).toFixed(2) + PERCENTAGE_SIGN 
                                        : Number(0)?.toFixed(2) + PERCENTAGE_SIGN;
                if (environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[KPI_NAMES[4]] = (this.dsxKpisNumbers[KPI_NAMES[4]] + 1) || 1;
                  this.dsxRowData[KPI_NAMES[4]] = (parseFloat(rowData[KPI_NAMES[4]].replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) +
                    (parseFloat(this.dsxRowData[KPI_NAMES[4]]?.replace(PERCENTAGE_SIGN, SINGLE_QUOTES)) || 0)) + PERCENTAGE_SIGN;
                }
                rowData[KPI_NAMES[4]] = this.getPrefix(reusabilityResp)+rowData[KPI_NAMES[4]];
              } else {
                rowData[KPI_NAMES[4]] = HYPHEN;
              }

              if(leadTimeToChangeResp?.leadTimeChange || leadTimeToChangeResp?.leadTimeChange == 0) {
                const { leadTimeChange } = leadTimeToChangeResp;
                rowData[KPI_NAMES[5]] = leadTimeChange >= 0 ? leadTimeChange + (leadTimeChange === 1 || leadTimeChange === 0 ? DAY : DAYS) : HYPHEN;
                if (rowData[KPI_NAMES[5]] != HYPHEN && environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[KPI_NAMES[5]] = (this.dsxKpisNumbers[KPI_NAMES[5]] + 1) || 1;
                  this.dsxRowData[KPI_NAMES[5]] = parseFloat(rowData[KPI_NAMES[5]]) + (parseFloat(this.dsxRowData[KPI_NAMES[5]]) || 0) + SINGLE_QUOTES;
                }
                rowData[KPI_NAMES[5]] = this.getPrefix(leadTimeToChangeResp)+rowData[KPI_NAMES[5]];
              } else {
                rowData[KPI_NAMES[5]] = HYPHEN;
              }

              if(availabilityResp?.availability || availabilityResp?.availability == 0) {
                rowData[KPI_NAMES[6]] = availabilityResp.availability >= 0 ? Number(availabilityResp.availability)?.toFixed(2) + PERCENTAGE_SIGN : HYPHEN;
                if (rowData[KPI_NAMES[6]] != HYPHEN && environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[KPI_NAMES[6]] = (this.dsxKpisNumbers[KPI_NAMES[6]] + 1) || 1;
                  this.dsxRowData[KPI_NAMES[6]] = parseFloat(rowData[KPI_NAMES[6]]) + (parseFloat(this.dsxRowData[KPI_NAMES[6]]) || 0) + SINGLE_QUOTES;
                }
                rowData[KPI_NAMES[6]] = this.getPrefix(availabilityResp)+rowData[KPI_NAMES[6]];
              } else {
                rowData[KPI_NAMES[6]] = HYPHEN;
              }

              if(dfCfrResp){
                const { hotfix, prod } = dfCfrResp;
                if (hotfix && prod) {
                  if ((hotfix.total || hotfix.total == 0) && (prod.total || prod.total == 0)) {
                    rowData[KPI_NAMES[0]] = hotfix.total + prod.total;
                    rowData[KPI_NAMES[7]] = hotfix.total != 0 ? Number(hotfix.total / (hotfix.total + prod.total) * 100).toFixed(2) + PERCENTAGE_SIGN : Number(0)?.toFixed(2) + PERCENTAGE_SIGN;
                  }
                } else {
                  rowData[KPI_NAMES[7]] = HYPHEN;
                  const hotfixCount = hotfix && hotfix.total;
                  const prodCount = prod && prod.total;
                  rowData[KPI_NAMES[0]] = (hotfixCount || hotfixCount == 0) ? hotfixCount : (prodCount || prodCount == 0) ? prodCount : HYPHEN;
                }
                if (environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  if (rowData[KPI_NAMES[7]] != HYPHEN) {
                    this.dsxKpisNumbers[KPI_NAMES[7]] = (this.dsxKpisNumbers[KPI_NAMES[7]] + 1) || 1;
                    this.dsxRowData[KPI_NAMES[7]] = parseFloat(rowData[KPI_NAMES[7]]) + (parseFloat(this.dsxRowData[KPI_NAMES[7]]) || 0) + SINGLE_QUOTES;
                  }
                  if (rowData[KPI_NAMES[0]] != HYPHEN) {
                    this.dsxKpisNumbers[KPI_NAMES[0]] = (this.dsxKpisNumbers[KPI_NAMES[0]] + 1) || 1;
                    this.dsxRowData[KPI_NAMES[0]] = parseFloat(rowData[KPI_NAMES[0]]) + (parseFloat(this.dsxRowData[KPI_NAMES[0]]) || 0) + SINGLE_QUOTES;
                  }
                }
                if(rowData[KPI_NAMES[0]] != HYPHEN){
                rowData[KPI_NAMES[0]] = this.getPrefix(dfCfrResp)+rowData[KPI_NAMES[0]];
                }
                if(rowData[KPI_NAMES[7]] != HYPHEN){
                 rowData[KPI_NAMES[7]] = this.getPrefix(dfCfrResp)+rowData[KPI_NAMES[7]];
                }
              } else {
                rowData[KPI_NAMES[7]] = HYPHEN;
                rowData[KPI_NAMES[0]] = HYPHEN;
              }

              if(typeof mttrResp?.mttr === 'number' && !isNaN(mttrResp?.mttr)){
                const valueInHrs = mttrResp?.respType == MANUAL ? Number(mttrResp?.mttr)?.toFixed(2) : Number(mttrResp.mttr / 3600)?.toFixed(2);
                rowData[KPI_NAMES[8]] = Number(valueInHrs) == 1 || Number(valueInHrs) == 0? valueInHrs + TIME_UNIT_HR : valueInHrs + TIME_UNIT_HRS;
                if (environment.DSX_PROGRAMS.includes(rowData.repo.name)) {
                  this.dsxKpisNumbers[KPI_NAMES[8]] = (this.dsxKpisNumbers[KPI_NAMES[8]] + 1) || 1;
                  this.dsxRowData[KPI_NAMES[8]] = parseFloat(rowData[KPI_NAMES[8]]) + (parseFloat(this.dsxRowData[KPI_NAMES[8]]) || 0) + SINGLE_QUOTES;
                }
                rowData[KPI_NAMES[8]] = this.getPrefix(mttrResp)+rowData[KPI_NAMES[8]];
              } else {
                rowData[KPI_NAMES[8]] = HYPHEN;
              }
          
            if (!this.EXTRA_PROGRAMS.includes(rowData?.repo?.name)) {
              const programLists = {
                commercialAndConsumer: environment.commercialAndConsumerProgramList,
                supplyChain: environment.supplyChainProgramList,
                dsx: environment.DSX_PROGRAMS,
              };

              const category = Object.entries(programLists).find(([_, list]) => list.includes(rowData?.repo?.name))?.[0];

              if (category) {
                switch (category) {
                  case COMMERCIAL_AND_CONSUMER:
                    commercialAndConsumerRowData.push(rowData);
                    commercialAndConsumerRowData.sort((a, b) => a.repo.name.localeCompare(b.repo.name));
                    break;
                  case SUPPLY_CHAIN:
                    supplyChainData.push(rowData);
                    supplyChainData.sort((a, b) => a.repo.name.localeCompare(b.repo.name));
                    break;
                  case DSX:
                    dsxData.push(rowData);
                    dsxData.sort((a, b) => a.repo.name.localeCompare(b.repo.name));
                    break;
                }
              }
            }
        })
        .catch((error) => {
          console.log(error);
        })
      promises.push(combinedDataPromise);
    }
    await Promise.all(promises);
    this.processGroupProgramsData(commercialAndConsumerRowData, commercialAndConsumerData, KPI_NAMES);
  }

  processGroupProgramsData = (commercialAndConsumerRowData, commercialAndConsumerData, KPI_NAMES) => {
    const b2bPrograms = [];
    const dtcPrograms = [];
    commercialAndConsumerRowData.forEach(row => { 
      if(row.repo?.name.includes(`${DASHBOARD_B2B} `)){
        b2bPrograms.push(row);
      } else if(row.repo?.name.includes(`${DASHBOARD_DTC} `)){
        dtcPrograms.push(row);
      } else {
        commercialAndConsumerData.push({
          data: {...row}
        });
      }
    });

    this.processGroupProgram(LABEL_B2B_PROGRAMS, b2bPrograms, commercialAndConsumerData, KPI_NAMES);
    this.processGroupProgram(LABEL_DTC_PROGRAMS, dtcPrograms, commercialAndConsumerData, KPI_NAMES);
  }

  processGroupProgram = (groupName, programsList, commercialAndConsumerData, KPI_NAMES)=> {
    if(programsList.length){
      if(programsList.length>1){
        const totals = {};
        const counts = {};
        programsList.forEach(program => {
          for (const key in program) {
            if(key!==REPO.toLowerCase()){
              let numericValue;
              let value = program[key];
              // Skip non-numeric values
              if (value === "-") {
                continue;
              }
              if(value.includes("ms") || value.includes("days") || value.includes("hrs")){
                numericValue = parseFloat(value.replace(/^\*/, "").replace(/[^0-9.-]+/g, ''));
              } else {
                numericValue = value.endsWith('%') ? parseFloat(value.replace(/^\*/, "")) : parseFloat(value.replace(/^\*/, ""));
              }
                // Initialize totals and counts
                if (totals[key] === null || totals[key] === undefined) {
                  totals[key] = 0;
                  counts[key] = 0;
                }
               
                if(key ===  this.KPI_LEAD_TIME || key === this.KPI_MEAN_TIME) {
                      if(totals[key] <= numericValue || totals[key] == null){
                        totals[key] = numericValue;
                      }
                }
                else {
                  totals[key] += numericValue;
                }
                counts[key]++;
            }
          }
        })

         // Calculate averages
         const groupMetricsAverages = {};
         KPI_NAMES.forEach(metricsName => {
          let unit;
          switch(metricsName) {
            case this.KPI_DEPLOYMENT:
              unit = SINGLE_QUOTES;
              break;
            case this.KPI_APP_RESPONSE:
              unit = TIME_UNIT_MS;
              break;
            case this.KPI_LEAD_TIME:
              unit = DAYS;
              break;
            case this.KPI_MEAN_TIME:
              unit = TIME_UNIT_HRS;
              break;
            default:
              unit = PERCENTAGE_SIGN;           
          }
          if(totals[metricsName] || totals[metricsName]==0){
            groupMetricsAverages[metricsName] = unit === PERCENTAGE_SIGN 
              ? Number(totals[metricsName] / counts[metricsName]).toFixed(2)+unit 
              : metricsName===this.KPI_MEAN_TIME ? Number(totals[metricsName]).toFixed(2)+unit : totals[metricsName]+unit;
          }else {
            groupMetricsAverages[metricsName] = '-';
          }
         });

         commercialAndConsumerData.push({
          data: {
            repo: groupName,
            ...groupMetricsAverages
          },
          children: programsList?.map(programMetrics=>({data:{...programMetrics}}))
         })
      } else {
        commercialAndConsumerData.push({
          data: {...programsList[0]}
        });
      }
    }
  }

  private getPrefix(resp) {
    return resp?.respType===MANUAL ? ASTERIX : SINGLE_QUOTES;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let val1 = data1[event.field];
      let val2 = data2[event.field];
      let result = 0;
      if (event.field === REPO.toLowerCase()) {
        const name1 = val1?.name;
        const name2 = val2?.name;
        if (name1 == null && name2 != null) {
          result = -1;
        } else if (name1 != null && name2 == null) {
          result = 1;
        } else if (name1 == null && name2 == null) {
          result = 0;
        } else {
          result = name1.localeCompare(name2);
        }
      } else {
        const num1 = parseFloat(val1?.split(' ')?.[0]);
        const num2 = parseFloat(val2?.split(' ')?.[0]);
        if (isNaN(num1)) {
          return event.order === 1 ? 1 : -1;
        }
        if (isNaN(num2)) {
          return event.order === 1 ? -1 : 1;
        }
        result = (num1 < num2) ? -1 : (num1 > num2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  setCategoryWiseTableData(commercialAndConsumerData, supplyChainData, dsxData, tableColumns) {
    this.categoryProgramList[0].data.tableColumns = tableColumns;
    this.categoryProgramList[0].data.tableData = commercialAndConsumerData;
    
    this.categoryProgramList[1].data.tableColumns = tableColumns;
    this.categoryProgramList[1].data.tableData = supplyChainData;

    this.categoryProgramList[2].data.tableColumns = tableColumns;
    this.categoryProgramList[2].data.tableData = dsxData;
  }

  redirectionFunction = (productId: any, productName: string) => {
    if (productId) {
      const productDashboard = this.productDashboards?.find(dashboard => dashboard.id === productId);
      const navigationExtras: NavigationExtras = {
        state: {
          dashboardDetails: productDashboard
        }
      };
      if (productId == HYPHEN) this.router.navigate(['/se-metrics'], { fragment: 'dsx' });
      else this.router.navigate(['/product-dashboard/product-dashboard-view/' + productId], navigationExtras);
    }
  }

  openTab(tabId: string): void {
    const tabs = document.querySelectorAll('.tab-pane');
    tabs.forEach(tab => {
      tab.classList.remove('active');
    });

    const selectedTab = document.getElementById(tabId);
    if (selectedTab) {
      selectedTab.classList.add('active');
      this.selectedTab = tabId;
    }
  }

}
