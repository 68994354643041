import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SeDashboardMetricsService } from '../se-metrics.service';
import { DashboardListService } from 'src/app/landing_page/dashboard-list/dashboard-list.service';
import { HttpParams } from '@angular/common/http';
import { API_PARAM_PAGE, API_PARAM_SIZE, PERCENTAGE, PERCENTAGE_SIGN } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-se-metrics-manual-data-form',
  templateUrl: './se-metrics-manual-data-form.component.html',
  styleUrls: ['./se-metrics-manual-data-form.component.scss']
})
export class SeMetricsManualDataFormComponent implements OnInit {

  @Input() modalInstance: NgbActiveModal;

  programManualDataConfigForm: FormGroup;

  programs: string[] = [];
  programIdMap: { [name: string]: string } = {};
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  availableMonths: string[] = [];
  years: number[] = [];
  kpiList: { name: string }[] = [
    { name: 'Deployment Frequency' }, { name: 'Automation Test Coverage' }, { name: 'Unit Test Coverage' },
    { name: 'App Response Time' }, { name: 'Software Reusability' }, { name: 'Availability' },
    { name: 'Lead Time To Change' }, { name: 'Change Fail Rate' }, { name: 'Mean time to resolve' }
  ];
  selectedKpis: any[] = [];

  constructor(private fb: FormBuilder, private seMetricesService: SeDashboardMetricsService,
    private dashboardListService: DashboardListService) { }

  ngOnInit(): void {
    this.fetchProductDashboards();
    this.programManualDataConfigForm = this.fb.group({
      programName: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      kpiData: this.fb.array([]),
      kpiData_2: [''],
      kpiData_3: [''],
      kpiData_5: [''],
      kpiData_6: [''],
      kpiData_8: [''],
      scheduledDeployment: [''],
      hotfixDeployment: [''],
      globalStoryPoints: [''],
      totalStoryPoints: [''],
      automatedTestCases: [''],
      totalTestCases: ['']
    });

    this.kpiList.forEach((_, i) => {
      this.programManualDataConfigForm.addControl(`kpi_${i}`, new FormControl(false));
    });

    const currentYear = new Date().getFullYear();
    this.years = [currentYear];

    function getMonthsTillNow(): string[] {
      const date = new Date();
      return Array.from({ length: date.getMonth() + 1 }, (_, i) =>
        new Date(date.getFullYear(), i).toLocaleString('default', { month: 'long' })
      );
    }
    this.availableMonths = getMonthsTillNow();

  }

  private kpiMappings = [
    { kpiName: 'Unit Test Coverage', kpiKey: 'UNIT_TEST_COVERAGE', formControl: 'kpiData_2', metricKey: 'value', kpiId: 2 },
    { kpiName: 'App Response Time', kpiKey: 'APP_RESPONSE_TIME', formControl: 'kpiData_3', metricKey: 'Overall Application Performance|Average Response Time (ms)', kpiId: 3 },
    { kpiName: 'Availability', kpiKey: 'AVAILABILITY', formControl: 'kpiData_5', metricKey: null, kpiId: 5 },
    { kpiName: 'Lead Time To Change', kpiKey: 'LEAD_TIME_TO_CHANGE', formControl: 'kpiData_6', metricKey: null, kpiId: 6 },
    { kpiName: 'Mean time to resolve', kpiKey: 'MEAN_TIME_TO_RESOLVE', formControl: 'kpiData_8', metricKey: null, kpiId: 8, transform: value => value / 3600 },
    { kpiName: 'Deployment Frequency', kpiKey: 'DF_AND_CFR', formControl: 'scheduledDeployment', metricKey: 'prod.success', kpiId: 0 },
    { kpiName: 'Deployment Frequency', kpiKey: 'DF_AND_CFR', formControl: 'hotfixDeployment', metricKey: 'hotfix.success', kpiId: 7 },
    { kpiName: 'Software Reusability', kpiKey: 'REUSABILITY', formControl: 'globalStoryPoints', metricKey: 'globalStoryPoints', kpiId: 4 },
    { kpiName: 'Software Reusability', kpiKey: 'REUSABILITY', formControl: 'totalStoryPoints', metricKey: 'totalStoryPoints', kpiId: 4 },
    { kpiName: 'Automation Test Coverage', kpiKey: 'TEST_AUTOMATION', formControl: 'automatedTestCases', metricKey: 'automatedTestCasesCount', kpiId: 1 },
    { kpiName: 'Automation Test Coverage', kpiKey: 'TEST_AUTOMATION', formControl: 'totalTestCases', metricKey: 'totalTestCasesCount', kpiId: 1 }
  ];

  private fetchProductDashboards() {
    const params = this.paramBuilder(0, '10');
    this.dashboardListService.fetchProductDashboards(params).subscribe(
      response => {
        if (response?.data) {
          this.programs = response.data.map((dashboard: any) => dashboard.name);
          this.programIdMap = response.data.reduce((map: any, dashboard: any) => {
            map[dashboard.name] = dashboard.id;
            return map;
          }, {});
        }
      },
      error => console.log(error)
    );
  }

  paramBuilder(page: number, pageSize: string): HttpParams {
    return new HttpParams()
      .set(API_PARAM_PAGE, page.toString())
      .set(API_PARAM_SIZE, pageSize);
  }

  onProgramMonthYearChange(): void {
    this.programManualDataConfigForm.patchValue({
      kpiData: [],
      kpiData_2: '',
      kpiData_3: '',
      kpiData_5: '',
      kpiData_6: '',
      kpiData_8: '',
      scheduledDeployment: '',
      hotfixDeployment: '',
      globalStoryPoints: '',
      totalStoryPoints: '',
      automatedTestCases: '',
      totalTestCases: ''
    });
    this.fetchExistingData();
  }

  private fetchExistingData(): void {
    const formData = this.programManualDataConfigForm.value;
    const selectedProgramId = this.programIdMap[formData.programName];
    if(formData?.year && formData?.month && selectedProgramId) {
    const period = `${formData?.year}-${(this.months?.indexOf(formData?.month) + 1)?.toString().padStart(2, '0')}`;
    if (selectedProgramId && period) {
      this.selectedKpis = []; 
      this.kpiMappings.forEach(mapping => {
        this.programManualDataConfigForm.get(`kpi_${mapping.kpiId}`)?.setValue(false);
      });
    
      this.seMetricesService.getManualEntry(selectedProgramId, period).subscribe(
        response => {
          if (response && response.metrics) {
            const processedKpiIds = new Set<number>();
            this.kpiMappings.forEach(mapping => {
              const metricValue = mapping.metricKey ? this.getNestedMetricValue(response.metrics[mapping.kpiKey], mapping.metricKey)
                : response.metrics[mapping.kpiKey];

              const finalValue = metricValue;
              if(finalValue !== null && finalValue !== undefined) {
              this.programManualDataConfigForm.patchValue({ [mapping.formControl]: finalValue});
              if (!processedKpiIds.has(mapping.kpiId)) {
                processedKpiIds.add(mapping.kpiId);
                this.programManualDataConfigForm.get(`kpi_${mapping.kpiId}`)?.setValue(true);
                if (mapping?.kpiId === 7) {
                  return;
                }
                if (mapping?.kpiId === 0) {
                  this.onKpiSelectionChange(0, true);
                } else {
                  this.onKpiSelectionChange(mapping.kpiId, true);
                }
              }
            }
            });
          }
        }
      );
    }
  }
  }

  private getNestedMetricValue(metric, key) {
    return key.split('.')?.reduce((acc, part) => acc?.[part], metric);
  }

  onKpiSelectionChange(index: number, triggeredByAutoSelection = false): void {
    const isSelected = this.programManualDataConfigForm.get(`kpi_${index}`)?.value;
    const kpiName = this.kpiList[index].name;
    if (isSelected) {
      this.selectedKpis.push({ name: kpiName, index });

      if (!triggeredByAutoSelection) {
      if (kpiName === 'Deployment Frequency') {
          const changeFailRateIndex = this.kpiList.findIndex(kpi => kpi.name === 'Change Fail Rate');
          if (changeFailRateIndex !== -1 && !this.programManualDataConfigForm.get(`kpi_${changeFailRateIndex}`)?.value) {
            this.programManualDataConfigForm.get(`kpi_${changeFailRateIndex}`)?.setValue(true);
          }
      } else if (kpiName === 'Change Fail Rate') {
          const deploymentFrequencyIndex = this.kpiList.findIndex(kpi => kpi.name === 'Deployment Frequency');
          if (deploymentFrequencyIndex !== -1 && !this.programManualDataConfigForm.get(`kpi_${deploymentFrequencyIndex}`)?.value) {
            this.programManualDataConfigForm.get(`kpi_${deploymentFrequencyIndex}`)?.setValue(true);
          }
        }
    }} else {
      this.selectedKpis = this.selectedKpis.filter(kpi => kpi.index !== index);

      if (kpiName === 'Deployment Frequency') {
        const changeFailRateIndex = this.kpiList.findIndex(kpi => kpi.name === 'Change Fail Rate');
        if (changeFailRateIndex !== -1 && !triggeredByAutoSelection) {
          this.programManualDataConfigForm.get(`kpi_${changeFailRateIndex}`)?.setValue(false);
        }
        this.selectedKpis = this.selectedKpis.filter(kpi => kpi.index !== changeFailRateIndex);
      } else if (kpiName === 'Change Fail Rate') {
        const deploymentFrequencyIndex = this.kpiList.findIndex(kpi => kpi.name === 'Deployment Frequency');
        if (deploymentFrequencyIndex !== -1 && !triggeredByAutoSelection) {
          this.programManualDataConfigForm.get(`kpi_${deploymentFrequencyIndex}`)?.setValue(false);
        }
        this.selectedKpis = this.selectedKpis.filter(kpi => kpi.index !== deploymentFrequencyIndex);
      }
    }
  }

  setFormFieldsToNullForKpi(kpiName: string) {
    this.kpiMappings.forEach(mapping => {
      if (mapping.kpiName === kpiName) {
        this.programManualDataConfigForm.patchValue({
          [mapping.formControl]: ''
        });
      }
    });
  }

  formSubmit(): void {
    if (this.programManualDataConfigForm.valid) {
      const formData = this.programManualDataConfigForm.value;
      const selectedProgramId = this.programIdMap[formData.programName];
      const date = new Date(`${formData.month} 1, ${formData.year}`);
      const numericMonth = date.getMonth() + 1;
      const period = `${formData.year}-${(this.months.indexOf(formData.month) + 1).toString().padStart(2, '0')}`;
      const payload: any = {
        id : `${formData.programName}:${period}`,
        productDashboardId: selectedProgramId,
        month: numericMonth,
        year: formData.year,
        kpiData: this.transformFormDataToKpiData(formData)
      };

      this.seMetricesService.getManualEntry(selectedProgramId, period).subscribe(
        response => {
          if (response && response.metrics) {
            this.seMetricesService.updateManualEntry(payload).subscribe(
              updateResponse => {
                this.modalInstance.dismiss();
              },
            );
          } else {
            this.seMetricesService.createManualEntry(payload).subscribe(
              createResponse => {
                this.modalInstance.dismiss();
              },);
          }
        });
    }
  }

  private transformFormDataToKpiData(formData: any): any {
    const kpiData: any = {};

    this.kpiMappings.forEach(mapping => {
      let check = false;
      this.selectedKpis.forEach(kpi => {
        if(kpi.name === mapping?.kpiName) {
          check = true;
          return;
        }
        else if(kpi?.name === 'Change Fail Rate'){
          check = true;
        }
      });
      if(!check) {
        this.setFormFieldsToNullForKpi(mapping?.kpiName);
        formData = this.programManualDataConfigForm.value;
      }
        const value = formData[mapping.formControl];
        const finalValue = value;

    if (value !== '' && value !== null && value !== undefined) {
      if (!kpiData[mapping?.kpiKey]) {
        kpiData[mapping?.kpiKey] = {};
      }

      if (mapping?.metricKey) {
        const keys = mapping?.metricKey?.split('.');
        if (keys?.length === 2) {
          const [category, key] = keys;
          if (!kpiData[mapping?.kpiKey][category]) {
            kpiData[mapping?.kpiKey][category] = {};
          }
          kpiData[mapping?.kpiKey][category][key] = finalValue;
          if (category === 'hotfix' || category === 'prod') {
            kpiData[mapping.kpiKey][category]['failure'] = 0;
            kpiData[mapping.kpiKey][category]['successPercentage'] = finalValue == 0 ? 0 : 100;
            kpiData[mapping.kpiKey][category]['total'] = finalValue;
          }
        } else {
          const kpiKey = mapping?.kpiKey;
          const metricKey = mapping?.metricKey;
          if (mapping.kpiId === 3) { 
              kpiData[kpiKey]['End User Experience|App|End User Response Time (ms)'] = null;
              kpiData[kpiKey][metricKey] = finalValue;
          } else if (mapping.kpiId === 2) {
              kpiData[kpiKey]['name'] = 'coverage';
              kpiData[kpiKey][metricKey] = finalValue.toString();
              kpiData[kpiKey]['formattedValue'] = `${finalValue}${PERCENTAGE_SIGN}`;
          } else {
              kpiData[kpiKey][metricKey] = finalValue;
          }
        }
      } else {
        kpiData[mapping.kpiKey] = finalValue;
      }
    }
    });
    return kpiData;
  }

  cancel(): void {
    this.modalInstance.dismiss();
  }
}
