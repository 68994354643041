import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {
  private apiCallsData:Map<string, any> = new Map();
  private selectedTab$ = new Subject<string>();
  selectedTabObservable$ = this.selectedTab$.asObservable();
  dashboardId:string; 

  set(key: string, value:any){
    this.apiCallsData.set(key, value)
  }

  get(key: string): any {
    return this.apiCallsData.get(key);
  }

  // Method to clear the cache
  clearCache(): void {
    this.apiCallsData?.clear();
  }

  setSelectedTab(tab: string) {
    this.selectedTab$.next(tab);
  }

  setDashboardId(dashboardId){
    this.dashboardId = dashboardId
  }
  
}
